import React, { useEffect } from 'react'
import '../../css/SpanEtat.css'

function SpanEtat({ etat, type = "span", etatFunction = "", actionBanque = "0" }) {

    const getStatus = (etat) => {
        if (etat == '0') {
            return "Non Traité";
        }
        else if (etat == '1') {
            return "Accorder";
        }
        else if (etat == '2') {
            return "Refuser";
        }
        else if (etat == '3') {
            return "Approuver";
        }
        else if (etat == '4') {
            return "Annuler";
        }
        else if (etat == '5') {
            return "Valider";
        }
        else if (etat == '9') {
            return "Financable";
        }
        else if (etat == '6') {
            return "Non Financable";
        }
        else if (etat == '7') {
            return "Rejeter";
        }
        else if (etat == '8') {
            return "Controler";
        }
        else if (etat == '10') {
            return "PA";
        }
        else if (etat == '11') {
            return "Complement"
        }
        else if (etat == '12') {
            return "Anomalie"
        }
        else if (etat == '13') {
            return "FBL Erronée"
        }
        else if (etat == '14') {
            return "Finançable 2EME Dossier"
        }
        else if (etat == '15') {
            return "Dossier Erroné"
        }
        else {
            return ''
        }
    }

    return (
        type === "span" ?
            <span className={
                etat == '0'
                    ? 'non-traite-color'
                    : etat == '1'
                        ? 'accorder-color'
                        : etat == '2'
                            ? 'refuser-color'
                            : etat == '3'
                                ? 'approuver-color'
                                : etat == '4'
                                    ? 'annuler-color'
                                    : etat == '5'
                                        ? 'valider-color'
                                        : etat == '9'
                                            ? 'financable-color'
                                            : etat == '6'
                                                ? 'non-financable-color'
                                                : etat == '7'
                                                    ? 'rejeter-color'
                                                    : etat == '8'
                                                        ? 'controler-color'
                                                        : etat == '10'
                                                            ? 'payment-accepter-color'
                                                            : etat == '11'
                                                                ? 'complement-color'
                                                                : etat == '12'
                                                                    ? 'anomalie-color'
                                                                    : etat == '13'
                                                                        ? 'fbl-erronee-color'
                                                                        : etat == '14'
                                                                            ? 'finan-eme-dossier-color'
                                                                            : etat == '15'
                                                                                ? 'dossier-errone-color'
                                                                                : ''}>
                {getStatus(etat)}
            </span>
            : type === "selectHome" ?
                <select className="form-select" value={etat} onChange={e => etatFunction(e.target.value)} >
                    <option value="">Tout</option>
                    <option value="0">Non Traité</option>
                    {/* <option value="1">Accorder</option> */}
                    <option value="2">Refuser</option>
                    <option value="3">Approuver</option>
                    <option value="4">Annuler</option>
                    {/* <option value="5">Valider</option> */}
                    <option value="9">Financable</option>
                    <option value="6">Non Financable</option>
                    <option value="7">Rejeter</option>
                    <option value="8">Controler</option>
                    <option value="10">Paiement Accepter</option>
                    <option value="11">Complement</option>
                    <option value="12">Anomalie</option>
                    <option value="13">FBL Erronée</option>
                    <option value="14">Finançable 2Eme Dossier</option>
                    <option value="15">Dossier Erroné</option>
                </select>
                : type === "selectDetailModal" ?
                    (actionBanque === '1') ? (
                        <select className="form-select" value={etat} onChange={e => etatFunction(e.target.value)} >
                            <option value="">Tout</option>
                            <option value="9">Financable</option>
                            <option value="6">Non Financable</option>
                            <option value="11">Complement</option>
                            <option value="14">Finançable 2Eme Dossier</option>
                            <option value="15">Dossier Erroné</option>
                        </select>
                    ) : (actionBanque === '3') ? (
                        <select className="form-select" value={etat} onChange={e => etatFunction(e.target.value)} >
                            <option value="">Tout</option>
                            <option value="4">Annuler</option>
                            <option value="7">Rejeter</option>
                        </select>
                    ) : (actionBanque === '5' || actionBanque === '6') ? (
                        <select className="form-select" value={etat} onChange={e => etatFunction(e.target.value)} >
                            <option value="">Tout</option>
                            <option value="10">Paiement Accepter</option>
                            {/* <option value="12">Anomalie</option> */}
                            <option value="13">FBL Erronée</option>
                        </select>
                    ) : (actionBanque === '9') ? (
                        <select className="form-select" value={etat} onChange={e => etatFunction(e.target.value)} >
                            <option value="">Tout</option>
                            <option value="8">Controler</option>
                            {/* <option value="7">Rejeter</option> */}
                            <option value="12">Anomalie</option>
                        </select>
                    ) : ""

                    : ""
    )
}

export default SpanEtat
import React,{useContext, useState} from 'react'
import '../css/ConsultPage.css'
import SpanEtat from './etat_components/SpanEtat'
import axios from 'axios';
import UserContext from '../context/UserContext';

function ConsultPage({ consultPageActive, setConsultPageActive, consultGroupList, getCommands, refreshHandle}) {
    const [etat, setEtat] = useState('');
    const { user } = useContext(UserContext);

    const validateSubmit =async(e)=>{
        e.preventDefault();
        await axios.post(`https://diardzair.com.dz/api/taksit/rest/set/etatAll/${etat}` ,
        JSON.stringify({username: user.user, orderId: user.orderId, list:consultGroupList,}),
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
                // 'Content-Type': 'application/json'
            },
        }).then(res=>{
            if (res.data.errorCode === 0) {
                setConsultPageActive(false);
                getCommands();
                refreshHandle();
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    return (
        <div style={{ display: consultPageActive == true ? 'block' : 'none' }} className='consult-group'>
            <div className='wrapper' onClick={() => setConsultPageActive(false)}></div>
            <div className='validate-box'>
                <div className='validate-title'>
                    <span>Consultation Groupée</span>
                </div>
                <div className='validate-form'>
                    <div className='row'>
                        <div className='col-lg-3'>
                            <label htmlFor="etat">Etat</label>
                        </div>
                        <div className='col-lg-9'>
                          <SpanEtat etat={etat} type="selectHome" etatFunction={setEtat} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='validate-submit'>
                            <span onClick={(e)=>etat === '' ? '' : validateSubmit(e)}>
                                Valider
                                <svg width="34px" heigh="20px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 61.06 40.25">
                                    <defs><style>{`.cls-modal-0{fill:#fff;}`}</style></defs>
                                    <g id="Layer_2" data-name="Layer 2">
                                        <g id="Layer_1-2" data-name="Layer 1">
                                            <g id="Layer_2-2" data-name="Layer 2">
                                                <g id="Layer_1-2-2" data-name="Layer 1-2">
                                                    <g id="Layer_2-2-2" data-name="Layer 2-2">
                                                        <g id="Layer_1-2-2-2" data-name="Layer 1-2-2"><path className="cls-modal-0" d="M61,5.42a5.47,5.47,0,0,1-2.25,4.44L16.55,40.21a.13.13,0,0,1-.18,0h0l-.21-.32h0L1,18.11a5.49,5.49,0,0,1,1.28-7.57,5.27,5.27,0,0,1,7.36,1.19l.07.11L19,25.2,52.62,1A5.27,5.27,0,0,1,60,2.2a.86.86,0,0,1,.07.1,5.37,5.37,0,0,1,1,3.12Z" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </span>
                        </div>
                    </div>
                </div>
                {/* <ul>
                    {
                        consultGroupList.map((item,idx)=>{
                            return (<li key={idx}>{item}</li>)
                        })
                    }
                </ul> */}
            </div>

        </div>
    )
}

export default ConsultPage